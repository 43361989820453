import React, { useState } from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import Logo from "../images/logo.svg";
import Nav from "../components/Nav";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/pro-light-svg-icons";

const Header = () => {
  const [mobileMenu, setMobileMenu] = useState(false);

  const query = useStaticQuery(graphql`
    query getMenu {
      wpMenu {
        menuItems {
          nodes {
            path
            id
            title: label
          }
        }
      }
    }
  `);
  const links = query.wpMenu.menuItems.nodes;

  return (
    <header className="relative lg:container mx-auto z-50 flex bg-purple text-white border-b-2 border-opacity-10 border-white">
      <Link to="/" className=" py-4 md:py-8 px-6 md:px-16">
        <img
          className="block w-48 md:w-56"
          src={Logo}
          alt="Logo"
          width={300}
          height={87}
        />
        <h1 className="sr-only">Old Dalby</h1>
      </Link>
      <Nav links={links} />
      <div className="ml-auto p-6 text-4xl border-l-2 border-white border-opacity-10 flex items-center md:hidden">
        <button
          aria-label="Menu Trigger"
          onClick={() => {
            setMobileMenu(!mobileMenu);
          }}
        >
          <FontAwesomeIcon icon={faBars} />
        </button>
      </div>
      <div
        className={`absolute top-0 mt-20 z-40 w-full h-screen bg-purple ${
          mobileMenu ? "block" : "hidden"
        } md:hidden`}
      >
        {links.map((link) => {
          return (
            <Link
              key={link.id}
              to={link.url}
              className="grid place-items-center p-12 font-bold border-t-2 border-opacity-10 border-white"
            >
              {link.title}
            </Link>
          );
        })}
      </div>
    </header>
  );
};

export default Header;
