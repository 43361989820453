import React from "react";
import { Link } from "gatsby";

const Nav = ({ data, links }) => {
  return (
    <nav className="ml-auto font-body hidden md:block">
      <ul className="flex h-full">
        {links.map((link, i) => {
          return (
            <li
              key={link.id}
              className={`border-l-2 border-opacity-10 border-white`}
            >
              <Link
                to={link.path}
                className="grid place-items-center h-full px-12 font-bold transition duration-100 hover:bg-teal"
              >
                {link.title}
              </Link>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default Nav;
