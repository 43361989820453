import * as React from "react";
import { graphql } from "gatsby";
import ButtonLink from "../components/ButtonLink";

export const fragment = graphql`
  fragment WpPage_PagelinksFragment on WpPage_Pagelinks {
    pageLinks {
      ... on WpPage {
        id
        title
        uri
      }
    }
  }
`;

const PageLinks = ({ links }) => {
  const { pageLinks } = links;

  const cols = `md:grid-cols-${pageLinks.length}`;

  return (
    <div
      className={`lg:container mx-auto mt-auto grid ${cols} border-t-2 border-white border-opacity-10`}
    >
      {pageLinks.map((link, i) => {
        return (
          <div
            key={link.id}
            className={`${
              i !== 0
                ? "border-t-2 md:border-t-0 md:border-l-2 border-opacity-10 border-white"
                : ""
            }`}
          >
            <ButtonLink
              text={link.title}
              url={link.uri}
              classes="py-6 px-6 md:px-12"
            />
          </div>
        );
      })}
    </div>
  );
};

export default PageLinks;
